export const expandDrawer = (element: HTMLElement) => {
  const el = element;
  const sectionHeight = el.scrollHeight;

  el.style.height = `${sectionHeight}px`;
};

export const collapseDrawer = (element: HTMLElement) => {
  const el = element;
  const sectionHeight = el.scrollHeight;

  const elementTransition = el.style.transition;
  el.style.transition = "";

  requestAnimationFrame(() => {
    el.style.height = `${sectionHeight}px`;
    el.style.transition = elementTransition;

    requestAnimationFrame(() => {
      el.style.height = "0";
    });
  });
};
